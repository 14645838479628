import { AddNewItemBtn } from "components/shared/buttons/AddNewItemBtn";
import { SmallLoader } from "components/shared/Loader";
import { FaPen } from "react-icons/fa";
import classNames from "utilities/ClassNames";
import { useOrganization } from "hooks/OrganizationContext";

interface Organization {
  id: string;
  name: string;
  desc: string;
}

interface HeaderProps {
  organizations: Organization[];
  handleAddNew: () => void;
  handleOrgEdit: () => void;
  isLoading: boolean;
}

const genOrganizationButtons = (
  organizations: Organization[],
  selectedOrganization: Organization,
  setSelectedOrganization: (org: Organization) => void,
  handleOrgEdit: () => void
) =>
  organizations.map((org) => (
    <button
      key={org.id}
      className={classNames(
        selectedOrganization.id === org.id
          ? "bg-[#757575] rounded-xl border border-white"
          : "bg-blue/35 rounded-md",
        "flex items-center justify-between py-0.5 xl:py-0.5 relative"
      )}
      onClick={() => setSelectedOrganization(org)
      }
    >
      <span className="cursor-pointer font-bold w-full h-full bg-transparent px-2 py-2 text-[13px] xl:text-sm 2xl:text-[16px] 4xl:text-lg flex gap-2 items-center">
        {org.name}
        {selectedOrganization.id === org.id && (
          <span
            onClick={handleOrgEdit}
            className="cursor-pointer text-white" // Adds some styling to indicate it's clickable
            aria-label="Edit Organization"
          >
            <FaPen />
          </span>
        )}
      </span>
    </button>
  ));

const ProjectHeader: React.FC<HeaderProps> = ({
  organizations,
  handleAddNew,
  handleOrgEdit,
  isLoading
}) => {
  const { selectedOrganization, setSelectedOrganization } = useOrganization();
  return (
    <div className="container">
      <nav className="flex gap-2 items-center">
        {genOrganizationButtons(
          organizations,
          selectedOrganization,
          setSelectedOrganization,
          handleOrgEdit
        )}
        <AddNewItemBtn onClick={handleAddNew} btnType="hollow" type="submit" isDisabled={false}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="15px"
            viewBox="0 -960 960 960"
            width="15px"
            fill="#FFFFFF"
          >
            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
          </svg>
          Add New
        </AddNewItemBtn>
        {isLoading && <SmallLoader />}
      </nav>
    </div>
  );
};

export default ProjectHeader;
