import { useState } from 'react';
import { FiChevronDown, FiChevronUp, FiCalendar } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface FilterProps {
  filters: {
    emailType: string;
    status: string;
    sendTo: string;
    startDate: Date | null;
    endDate: Date | null;
  };
  setFilters: (filters: any) => void;
}

export default function EmailFilters({ filters, setFilters }: FilterProps) {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const emailTypes = [
    { id: 'welcome', label: 'Welcome Email' },
    { id: 'verification', label: 'Verification' },
    { id: 'reset_password', label: 'Reset Password' },
    { id: 'notification', label: 'Notification' },
    { id: 'newsletter', label: 'Newsletter' }
  ];

  const statuses = [
    { id: 'sent', label: 'Sent' },
    { id: 'failed', label: 'Failed' },
    { id: 'pending', label: 'Pending' },
    { id: 'bounced', label: 'Bounced' }
  ];

  const handleDropdownClick = (dropdownName: string) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleOptionSelect = (dropdownName: string, value: string) => {
    setFilters((prev: any) => ({ ...prev, [dropdownName]: value }));
    setOpenDropdown(null);
  };

  const clearFilters = () => {
    setFilters({
      emailType: '',
      status: '',
      sendTo: '',
      startDate: null,
      endDate: null
    });
  };

  const Dropdown = ({ name, options, label }: { name: string; options: any[]; label: string }) => (
    <div className="relative">
      <button
        onClick={() => handleDropdownClick(name)}
        className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
      >
        {filters[name as keyof typeof filters]?.toString() || label}
        {openDropdown === name ? <FiChevronUp /> : <FiChevronDown />}
      </button>

      {openDropdown === name && (
        <>
          <div
            className="fixed inset-0 z-10"
            onClick={() => setOpenDropdown(null)}
          />
          <div className="absolute top-full left-0 mt-2 w-48 bg-[#2A2A2A] rounded-md shadow-lg z-20 py-1 border border-white/[0.08]">
            {options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(name, option.label)}
                className="w-full text-left px-4 py-2 text-gray-400 hover:bg-[#3A3A3A] hover:text-white"
              >
                {option.label}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex items-center gap-3">
        
        
        <div className="relative">
          <button
            onClick={() => setShowDatePicker(!showDatePicker)}
            className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-3 py-2 text-gray-400 hover:bg-[#3A3A3A]"
          >
            <FiCalendar />
            {(filters.startDate && filters.endDate) 
              ? `${new Date(filters.startDate).toLocaleDateString()} - ${new Date(filters.endDate).toLocaleDateString()}`
              : 'Select Duration'
            }
          </button>
          
          {showDatePicker && (
            <>
              <div
                className="fixed inset-0 z-10"
                onClick={() => setShowDatePicker(false)}
              />
              <div className="absolute top-full left-0 mt-2 z-20 bg-[#2A2A2A] rounded-lg p-4 border border-white/[0.08]">
                <DatePicker
                  selectsRange={true}
                  startDate={filters.startDate}
                  endDate={filters.endDate}
                  onChange={(update: [Date | null, Date | null]) => {
                    setFilters((prev: any) => ({
                      ...prev,
                      startDate: update[0],
                      endDate: update[1]
                    }));
                    if (update[0] && update[1]) {
                      setShowDatePicker(false);
                    }
                  }}
                  inline
                />
              </div>
            </>
          )}
        </div>
        
        <Dropdown
          name="emailType"
          options={emailTypes}
          label="Email Type"
        />
        
        <Dropdown
          name="status"
          options={statuses}
          label="Status"
        />

        {Object.values(filters).some(Boolean) && (
          <button 
            onClick={clearFilters}
            className="text-red-400 hover:text-red-300"
          >
            Clear all
          </button>
        )}
      </div>

      <div className="flex items-center gap-3">
        <button className="flex items-center gap-2 bg-[#2A2A2A] rounded-md px-4 py-2 text-white">
          ↓ Export
        </button>
      </div>
    </div>
  );
} 