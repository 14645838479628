import * as Tabs from '@radix-ui/react-tabs';
import { motion, AnimatePresence } from 'framer-motion';
import ActivityFilters from './filters/ActivityFilters';
import EmailFilters from './filters/EmailFilters';
import AuditFiltersComponent from './filters/ActivityFilters';

interface LogsTabsProps {
  children: React.ReactNode;
  activeTab: string;
  onTabChange: (value: string) => void;
  activityFilters: any;
  setActivityFilters: (filters: any) => void;
  emailFilters: any;
  setEmailFilters: (filters: any) => void;
  auditFilters: any;
  setAuditFilters: (filters: any) => void;
}

export default function LogsTabs({ 
  children, 
  activeTab, 
  onTabChange,
  activityFilters,
  setActivityFilters,
  emailFilters,
  setEmailFilters,
  auditFilters,
  setAuditFilters
}: LogsTabsProps) {
  return (
    <div>
      <Tabs.Root value={activeTab} onValueChange={onTabChange}>
        <div className="sticky top-0 pt-4 bg-black z-20 pb-4">
          <Tabs.List className="flex w-full">
            <Tabs.Trigger
              value="activity"
              className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[200px] ${
                activeTab === 'activity' 
                  ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white rounded-lg'
                  : 'bg-[#2A2A2A] text-gray-400 rounded-l-lg '
              }`}
            >
              Activity Logs
            </Tabs.Trigger>
            <Tabs.Trigger
              value="email"
              className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[200px] ${
                activeTab === 'email' 
                  ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white  rounded-lg'
                  : 'bg-[#2A2A2A] text-gray-400 '
              }`}
            >
              Email Logs
            </Tabs.Trigger>
            <Tabs.Trigger
              value="audit"
              className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[200px] ${
                activeTab === 'audit' 
                  ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white  rounded-lg'
                  : 'bg-[#2A2A2A] text-gray-400 rounded-r-lg'
              }`}
            >
              Audit Trails
            </Tabs.Trigger>
            <div className="flex-1 bg-[#2A2A2A] h-full" />
          </Tabs.List>

          <div className="mt-4">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'activity' && (
                  <ActivityFilters
                    filters={activityFilters}
                    setFilters={setActivityFilters}
                  />
                )}
                {activeTab === 'email' && (
                  <EmailFilters 
                    filters={emailFilters}
                    setFilters={setEmailFilters}
                  />
                )}
                {activeTab === 'audit' && (
                  <AuditFiltersComponent 
                    filters={auditFilters}
                    setFilters={setAuditFilters}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.2 }}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </Tabs.Root>
    </div>
  );
} 