import * as Tabs from '@radix-ui/react-tabs';
import { motion, AnimatePresence } from 'framer-motion';
import OrganizationFilters from './OrganizationFilters';
import UsersFilters from './UsersFilters';
import UsersTable from './UsersTable';

interface OrganizationTabsProps {
  children: React.ReactNode;
  activeTab: string;
  onTabChange: (value: string) => void;
  setRefreshMode: (value: string) => void;
  setOrgFilters: (filters: any) => void;
  setUserFilters: (filters: any) => void;
  orgFilters: any;
  userFilters: any;
}

export default function OrganizationTabs({ children, activeTab, onTabChange, setRefreshMode, setOrgFilters, setUserFilters, orgFilters, userFilters }: OrganizationTabsProps) {
  return (
    <div>
      <Tabs.Root value={activeTab} onValueChange={onTabChange}>
        <div className="sticky top-0 pt-4 bg-black z-20 pb-4">
          <Tabs.List className="flex w-full">
            <Tabs.Trigger
              value="organizations"
              className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[200px] ${
                activeTab === 'organizations' 
                  ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white rounded-lg'
                  : 'bg-[#2A2A2A] text-gray-400 rounded-l-lg'
              }`}
            >
              Organizations
            </Tabs.Trigger>
            <Tabs.Trigger
              value="users"
              className={`px-6 py-2.5 text-sm transition-colors relative flex-1 max-w-[200px] ${
                activeTab === 'users' 
                  ? 'bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] text-white rounded-lg' 
                  : 'bg-[#2A2A2A] text-gray-400 rounded-r-lg'
              }`}
            >
              Users
            </Tabs.Trigger>
            <div className="flex-1 bg-[#2A2A2A] h-full" />
          </Tabs.List>

          <div className="mt-4">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTab}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.2 }}
              >
                {activeTab === 'organizations' ? (
                  <OrganizationFilters 
                    setRefreshMode={setRefreshMode} 
                    setFilters={setOrgFilters}
                    filters={orgFilters}
                  /> 
                ) : (
                  <UsersFilters 
                    setFilters={setUserFilters}
                    filters={userFilters}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.2 }}
          >
            <Tabs.Content value="organizations">
              {activeTab === 'organizations' && children}
            </Tabs.Content>

            <Tabs.Content value="users">
              {activeTab === 'users' && <UsersTable filters={userFilters} />}
            </Tabs.Content>
          </motion.div>
        </AnimatePresence>
      </Tabs.Root>
    </div>
  );
} 