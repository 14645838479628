import { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { auditTrailsMockData } from '@/lib/constants';
import { useNavigate } from 'react-router-dom';

interface AuditTrailsProps {
  filters: {
    userName: string;
    userType: string;
    change: string;
    date: string;
  }
}

export default function AuditTrails({ filters }: AuditTrailsProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredLogs = auditTrailsMockData.filter(log => {
    if (filters.userName && !log.user.toLowerCase().includes(filters.userName.toLowerCase())) return false;
    if (filters.userType && log.userType !== filters.userType) return false;
    if (filters.change && log.change !== filters.change) return false;
    if (filters.date && !log.date.includes(filters.date)) return false;
    return true;
  });

  const totalPages = Math.ceil(filteredLogs.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentLogs = filteredLogs.slice(startIndex, endIndex);
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
              <th className="text-left p-4">Date</th>
              <th className="text-left p-4">User Name</th>
              <th className="text-left p-4">User Type</th>
              <th className="text-left p-4">Change</th>
              <th className="text-left p-4">Description</th>
            </tr>
          </thead>
          <tbody>
            {currentLogs.map((log, index) => (
              <tr key={index} className="border-b border-white/[0.08] hover:bg-[#3A3A3A] cursor-pointer"
                onClick={() => navigate(`/logs/activity/${index}`)}>
                <td className="p-4 text-gray-400">{log.date}</td>
                <td className="p-4">
                  <span className="text-white">{log.user}</span>
                </td>
                <td className="p-4 text-gray-400">{log.userType}</td>
                <td className="p-4">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    log.change.toLowerCase().includes('update') ? 'bg-blue-900/30 text-blue-400' :
                    log.change.toLowerCase().includes('delete') ? 'bg-[#e11d486b] text-red-500' :
                    'bg-[#1C3829] text-[#4ADE80]'
                  }`}>
                    {log.change}
                  </span>
                </td>
                <td className="p-4 text-gray-400">{log.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4 px-4">
        <div className="text-sm text-gray-400">
          Showing {startIndex + 1} to {Math.min(endIndex, filteredLogs.length)} of {filteredLogs.length} entries
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="p-2 text-gray-400 hover:text-white disabled:opacity-50"
          >
            <FiChevronLeft />
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`w-8 h-8 rounded-lg ${
                currentPage === page
                  ? 'bg-blue-600 text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="p-2 text-gray-400 hover:text-white disabled:opacity-50"
          >
            <FiChevronRight />
          </button>
        </div>
      </div>
    </>
  );
} 