import { useState } from 'react';
import classNames from 'utilities/ClassNames';

interface TimeFilterProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const TimeFilter = ({ label, isActive, onClick }: TimeFilterProps) => (
  <button
    onClick={onClick}
    className={classNames(
      'px-6 py-2 relative z-10 transition-colors duration-200 text-sm',
      isActive ? 'text-white' : 'text-gray-400 hover:text-gray-300'
    )}
  >
    {label}
  </button>
);

interface StatWidgetProps {
  label: string;
  value: string | number;
  change?: string;
}

const StatWidget = ({ label, value, change }: StatWidgetProps) => (
  <div className="bg-[#1A1A1A] rounded-xl p-6 border border-white/[0.08]">
    <div className="text-gray-400 text-sm mb-2">{label}</div>
    <div className="flex items-center gap-2">
      <span className="text-white text-2xl font-medium">{value}</span>
      {change && (
        <span className={classNames(
          'px-2 py-0.5 text-xs rounded-full',
          change === '0%' ? 'bg-white/[0.08] text-gray-400' : 
          change.startsWith('+') ? 'bg-[#1C3829] text-[#4ADE80]' : 
          'bg-[#e11d486b] text-red-500'
        )}>
          {change}
        </span>
      )}
    </div>
  </div>
);

export default function LogsHeader({ selectedTime, setSelectedTime }: { selectedTime: string, setSelectedTime: (time: string) => void }) {
  const timeFilters = [
    { id: 'all', label: 'All Time' },
    { id: '12m', label: '12 Months' },
    { id: '30d', label: '30 Days' },
    { id: '7d', label: '7 Days' },
    { id: '24h', label: '24 Hour' },
  ];

  const selectedIndex = timeFilters.findIndex(filter => filter.id === selectedTime);

  return (
    <div className="mb-8">
      <div className="mb-6">
        <div className="inline-flex bg-[#1A1A1A] rounded-lg relative border border-white/[0.08]">
          <div
            className="absolute h-full top-0 bg-gradient-to-r from-[#65f4cdcc] to-[#4799e9b3] rounded-lg transition-all duration-300 ease-out"
            style={{
              width: `${100 / timeFilters.length}%`,
              left: `${(selectedIndex * 100) / timeFilters.length}%`,
            }}
          />
          
          <div className="relative flex">
            {timeFilters.map((filter) => (
              <TimeFilter
                key={filter.id}
                label={filter.label}
                isActive={selectedTime === filter.id}
                onClick={() => setSelectedTime(filter.id)}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatWidget
          label="Total Activities"
          value="2,345"
          change="+15%"
        />
        <StatWidget
          label="Email Sent"
          value="1,234"
          change="+8%"
        />
        <StatWidget
          label="Failed Emails"
          value="45"
          change="-5%"
        />
        <StatWidget
          label="System Changes"
          value="567"
          change="+12%"
        />
      </div>
    </div>
  );
} 