import React, { createContext, useContext, useState, ReactNode } from "react";

// Update the type to be an array of numbers, initialized to [null, null]
interface CoordinatesContextType {
  coordinates: [number | null, number | null];  // Allowing nulls for uninitialized state
  setCoordinates: (newCoordinates: [number | null, number | null]) => void;
}

const CoordinatesContext = createContext<CoordinatesContextType | undefined>(
  undefined
);

export const CoordinatesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [coordinates, setCoordinates] = useState<[number | null, number | null]>([null, null]);

  return (
    <CoordinatesContext.Provider value={{ coordinates, setCoordinates }}>
      {children}
    </CoordinatesContext.Provider>
  );
};

export const useCoordinates = () => {
  const context = useContext(CoordinatesContext);
  if (!context) {
    throw new Error("useCoordinates must be used within a CoordinatesProvider");
  }
  return context;
};
