import BorderGradient from 'components/shared/BorderGradient/BorderGradient';
import React from 'react';
import { FiX, FiCheck, FiTrash2 } from 'react-icons/fi';

interface ProjectReassignModalProps {
    isOpen: boolean;
    onClose: () => void;
    userData: {
        id: string;
        name: string;
        status: string;
        startDate: string;
        endDate: string;
        invoiceDate: string;
        dueDate: string;
        paymentStatus: string;
        paymentMethod: string;
        projects: Array<{
            name: string;
            role: string;
            owner: string;
            isOwner: boolean;
        }>;
        organizations: Array<{
            id: string;
            name: string;
            role: string;
            joinedDate: string;
            product: string;
            projectCount: number;
        }>;
    };
    allUsers: any[];
}

const CustomCheckbox = ({ checked }: { checked: boolean }) => (
  <div className={`
    w-5 h-5 rounded-md border transition-all duration-200
    flex items-center justify-center
    ${checked 
      ? 'border-green-500 bg-green-500/20' 
      : 'border-gray-600 bg-[#2A2A2A]'}
  `}>
    {checked && (
      <FiCheck 
        size={14} 
        className="text-green-500"
      />
    )}
  </div>
);

const ProjectReassignModal: React.FC<ProjectReassignModalProps> = ({
    isOpen,
    onClose,
    userData,
    allUsers
}) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <BorderGradient>
                <div className="bg-[#1E1E1E] rounded-lg w-full max-w-4xl">
                    {/* Header */}
                <div className="flex justify-between items-center p-6 border-b border-white/[0.08]">
                    <div>
                        <h2 className="text-xl text-white">{userData.name}</h2>
                        <p className="text-sm text-gray-400">{userData.organizations[0].name}</p>
                    </div>
                    <div className="flex items-center gap-6">
                        <div className="text-sm">
                            <span className="text-gray-400">No of Projects</span>
                            <span className="ml-2 text-white font-medium">{userData.organizations[0].projectCount}</span>
                        </div>
                        <button onClick={onClose} className="text-gray-400 hover:text-white">
                            <FiX size={20} />
                        </button>
                    </div>
                </div>

                {/* Project Table */}
                <div className="p-6">
                    <table className="w-full">
                        <thead>
                            <tr className="text-sm text-gray-400 border-b border-white/[0.08]">
                                <th className="text-left pb-3">Project Name</th>
                                <th className="text-left pb-3">Role</th>
                                <th className="text-center pb-3 w-24">Owner</th>
                                <th className="text-left pb-3">Reassign To</th>
                                <th className="text-right pb-3">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.projects.map((project, index) => (
                                <tr key={index} className="border-b border-white/[0.08]">
                                    <td className="py-4 text-white">{project.name}</td>
                                    <td className="py-4 text-gray-400">{project.role}</td>
                                    <td className="py-4">
                                        <div className="flex justify-center">
                                            <CustomCheckbox checked={project.isOwner} />
                                        </div>
                                    </td>
                                    <td className="py-4">
                                        <select 
                                            className={`
                                                bg-[#2A2A2A] border border-white/[0.08] rounded px-3 py-1.5 text-sm min-w-[200px]
                                                ${!project.isOwner && 'opacity-30 cursor-not-allowed'}
                                            `}
                                            disabled={!project.isOwner}
                                        >
                                            <option value="">Select user</option>
                                            {allUsers.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="py-4 text-right">
                                        <button 
                                            className={`
                                                p-2 rounded-lg hover:bg-white/[0.08] transition-colors
                                                ${project.isOwner 
                                                    ? 'text-red-400 hover:text-red-300 cursor-pointer' 
                                                    : 'text-gray-500 opacity-30 cursor-not-allowed'}
                                            `}
                                            disabled={!project.isOwner}
                                            title={project.isOwner ? "Delete access" : "Cannot delete access"}
                                        >
                                            <FiTrash2 size={18} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </BorderGradient>
        </div>
    );
};

export default ProjectReassignModal; 