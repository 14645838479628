import { FaArrowLeft } from "react-icons/fa";

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <div className="bg-gradient-to-b from-green to-blue rounded-md p-0.5">
      <button className="bg-lightGray rounded-md px-4 py-1.5 flex gap-2 items-center justify-center" onClick={onClick} type="button">
        <span className="text-blue">Back</span>
        <FaArrowLeft className="text-blue" />
      </button>
    </div>
  );
};

export default BackButton;
