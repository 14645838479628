// components/OrganizationForm.tsx
import React, { useState } from "react";
import { AddNewItemBtn } from "components/shared/buttons/AddNewItemBtn";
import FormInput from "./FormInput";
import { postOgranization, editOrganization, removeOrganization } from "api/organizations";
import Modal from "components/Modal/ConfirmationModal";
import { RemoveItemBtn } from "components/shared/buttons/RemoveItemBtn";

interface OrganizationFormProps {
  setRefreshMode: (value: string) => void;
  setError: (value: string) => void;
  mode: 'edit' | 'create';
  formState: { id: string; name: string; desc: string };
  setFormState: React.Dispatch<React.SetStateAction<{ id: string; name: string; desc: string }>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({
  setRefreshMode,
  setError,
  mode,
  formState,
  setFormState,
  setIsLoading
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the form's default submit action
    setIsLoading(true);
    if (mode === 'create') {
      const response = await postOgranization(formState);
      if (response.status === 200) {
        setRefreshMode('organizations');
      } else {
        setError(response.data);
      }
    }

    if (mode === 'edit') {
      const response = await editOrganization(formState);
      if (response.status === 200 || response.status === 204) {
        setRefreshMode('organizations');
      } else {
        setError(response.data);
      }
    }
    setIsLoading(false);
  };


  const handleRemoveItem = async () => {
    const response = await removeOrganization(formState);
    if (response.status === 200 || response.status === 204) {
      setFormState({ name: '', id: '', desc: '' });
      setRefreshMode('organizations');
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const confirmRemove = async () => {
    setIsLoading(true);
    await handleRemoveItem();
    closeModal();
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="h-full text-sm xl:text-md 2xl:text-lg">
      <div className="flex justify-between gap-4 h-full">
        <div className="w-full">
          <FormInput
            id="orgName"
            name="name"
            value={formState.name}
            placeholder="Organization Name"
            onChange={handleInputChange}
          />
          <FormInput
            id="orgDescription"
            name="desc"
            value={formState.desc}
            placeholder="Organization Description"
            onChange={handleInputChange}
          />
        </div>

        <div className="w-full flex flex-wrap">
          <div className="flex justify-end w-full h-max mt-auto gap-2">
            {mode === 'edit' && <RemoveItemBtn onClick={openModal} isDisabled={false} type="button">
              Remove Organization
            </RemoveItemBtn>}
            <AddNewItemBtn btnType="filled" type="submit" isDisabled={false}>
              {mode === 'create' && <span>Create Organization</span>}
              {mode === 'edit' && <span>Edit Organization</span>}
            </AddNewItemBtn>
          </div>
        </div>
      </div>

      {/* Modal Component */}
      <Modal
        isOpen={isModalOpen}
        message="Are you sure you want to delete this item?"
        onConfirm={confirmRemove}
        onCancel={closeModal}
      />
    </form>
  );
};

export default OrganizationForm;
