import React, { useEffect, useState } from "react";
import { AddNewItemBtn } from "components/shared/buttons/AddNewItemBtn";
import FormInput from "./FormInput";
import SelectInput from "./SelectInput";
import CoordinatesInput from "./CoordinatesInput";
import { useCoordinates } from "hooks/CoordinatesContext";
import { postSolution, editSolution, removeSolution } from "api/solutions";
import { RemoveItemBtn } from "components/shared/buttons/RemoveItemBtn";
import Modal from "components/Modal/ConfirmationModal";

const NewProjectForm: React.FC<any> = ({
  setRefreshMode,
  setError,
  mode,
  formState,
  setFormState,
  setIsLoading,
  selectedOrganization,
  selectedProject
}) => {
  const { coordinates, setCoordinates } = useCoordinates();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (coordinates[0] !== null && coordinates[1] !== null) {
      setFormState((prev: any) => ({
        ...prev,
        coordinates: coordinates,
      }));
    }
  }, [coordinates]);

  useEffect(() => {
    if (mode === "edit" && selectedProject) {
      setFormState({
        projectName: selectedProject.name,
        productLocation: selectedProject.location,
        audience: selectedProject.status,
        building: selectedProject.desc,
        coordinates: selectedProject.coordinates || [null, null],
      });
      setCoordinates(selectedProject.coordinates || [null, null]);
    } else if (mode === "create") {
      setFormState({
        projectName: "",
        productLocation: "",
        audience: "",
        building: "",
        coordinates: [null, null],
      });
      setCoordinates([null, null]);
    }
  }, [mode, selectedProject, setCoordinates]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "lat" || name === "lng") {
      // Update coordinates array with numbers, or null if empty
      const index = name === "lat" ? 0 : 1;
      const updatedCoordinates = [...formState.coordinates];
      updatedCoordinates[index] = value ? parseFloat(value) : null;  // Convert to number or null
      setFormState((prev: any) => ({
        ...prev,
        coordinates: updatedCoordinates as [number | null, number | null],  // Explicitly type the coordinates
      }));
      setCoordinates(updatedCoordinates as [number | null, number | null]);
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const preparedData = {
      name: formState.projectName,
      desc: formState.building,
      location: formState.productLocation,
      coordinates: JSON.stringify(formState.coordinates)
    };
    if (mode === 'create') {
      const response = await postSolution(selectedOrganization.id, preparedData);
      if (response.status === 200 || response.status === 204) {
        setRefreshMode('solutions');
      }
      else {
        setError(response.data);
      }
    }
    if (mode === 'edit') {
      const response = await editSolution(selectedOrganization.id, selectedProject.id, preparedData);
      if (response.status === 200 || response.status === 204) {
        setRefreshMode('solutions');
      }
      else {
        setError(response.data);
      }
    }
    setCoordinates([null, null]);
    setIsLoading(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const audienceOptions = [
    { value: "Public", label: "Public" },
    { value: "Private", label: "Private" },
    { value: "Internal", label: "Internal" },
  ];

  const locationOptions = [
    { value: "Riyadh", label: "Riyadh" },
    { value: "Jeddah", label: "Jeddah" },
    { value: "Dammam", label: "Dammam" },
  ];

  const handleRemoveItem = async () => {
    const response = await removeSolution(selectedOrganization.id, selectedProject.id);
    if (response.status === 200 || response.status === 204) {
      setFormState({
        projectName: "",
        productLocation: "",
        audience: "",
        building: "",
        coordinates: [null, null] as [number | null, number | null],
      });
      setRefreshMode('solutions');
    }
  };

  const confirmRemove = async () => {
    await handleRemoveItem();
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit} className="h-full text-sm xl:text-md 2xl:text-lg">
      <div className="flex justify-between gap-4 h-[85%]">
        <div className="w-full flex flex-wrap justify-between items-center">
          <FormInput
            id="projectName"
            name="projectName"
            value={formState.projectName}
            placeholder="Project Name"
            onChange={handleInputChange}
          />
          <SelectInput
            id="productLocation"
            name="productLocation" // Match the key in formState
            label="Product Location" // Display label
            value={formState.productLocation}
            options={locationOptions}
            onChange={handleInputChange}
          />
          {/* <SelectInput
            id="audience"
            name="Audience"
            value={formState.audience}
            options={audienceOptions}
            onChange={handleInputChange}
          /> */}
        </div>

        <div className="w-full flex flex-wrap justify-between items-center">
          <FormInput
            id="building"
            name="building"
            value={formState.building}
            placeholder="What are you Building?"
            onChange={handleInputChange}
          />
          <CoordinatesInput
            value={formState.coordinates}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex justify-end w-full h-max mt-2 gap-2">
        {mode === 'edit' && <RemoveItemBtn onClick={openModal} isDisabled={false} type="button">
          Remove Project
        </RemoveItemBtn>}
        <AddNewItemBtn btnType="filled" type="submit" isDisabled={false}>
          {mode === 'create' && <span>Create Project</span>}
          {mode === 'edit' && <span>Edit Project</span>}
        </AddNewItemBtn>
      </div>

      <Modal
        isOpen={isModalOpen}
        message="Are you sure you want to delete this item?"
        onConfirm={confirmRemove}
        onCancel={closeModal}
      />
    </form>
  );
};

export default NewProjectForm;
