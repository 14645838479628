import { authApi, projectsapi, coreApi } from "utilities/Axios";

export const getAllUsersForAdmin = () => {
  return authApi.get("/api/user/UsersForAdmin");
};

export const UpdateUserRole = (data: any) => {
  return authApi.post("/api/user/assignrole", data,);
};

export const getRoles = () => {
  return authApi.get("/api/user/UserRoles");
};

export const getAllUsers = () => {
  return authApi.get("/api/user/Users");
};

export const deleteUser = (data: any) => {
  return projectsapi.get("/user/deleteuser" + `?id=${data}`);
};


export const getAllUsersWithOrganization = () => {
  return coreApi.get("/api/Admin/all-users-with-org");
};

