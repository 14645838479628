// components/CoordinatesInput.tsx
import React from "react";
import { FaSearch } from "react-icons/fa";

interface CoordinatesInputProps {
  value: [number | null, number | null];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CoordinatesInput: React.FC<CoordinatesInputProps> = ({ value, onChange }) => {
  return (
    <div className="mb-4 w-full relative">
      <label
        htmlFor="coordinates"
        className="block text-md font-medium text-gray-600"
      >
        Input Coordinates or Select from the Map Below
      </label>
      <div className="relative flex gap-6">
        <input
          type="number"
          id="lat"
          name="lat"
          required
          placeholder="Latitude"
          value={value[0] === null ? 0 : value[0].toFixed(2)}
          onChange={onChange}
          className="w-full p-2 border bg-transparent border-x-0 border-t-0 focus:outline-none"
        />
        <input
          type="number"
          required
          id="lng"
          name="lng"
          placeholder="Longitude"
          value={value[1] === null ? 0 : value[1].toFixed(2)}
          onChange={onChange}
          className="w-full p-2 border bg-transparent border-x-0 border-t-0 focus:outline-none"
        />
      </div>
    </div>
  );
};

export default CoordinatesInput;
