import React from "react";
import classNames from "utilities/ClassNames";
import { BorderGradientProps } from "./domain/types";

const BorderGradient: React.FC<BorderGradientProps> = ({ className = '', children }) => {
  return (
    <div className={classNames(className, 'gradient p-[1px] rounded-lg overflow-hidden')}>
      {children}
    </div>
  );
};

export default BorderGradient;