import classNames from "utilities/ClassNames";

export const RemoveItemBtn = ({
  onClick,
  type,
  isDisabled,
  children,
}: {
  onClick?: any;
  type: 'submit' | 'button';
  isDisabled: boolean;
  children: React.ReactNode; // This can be any valid React child (text, JSX, components)
}) => {
  const btnClasses = isDisabled ? 'bg-[#8A8A8A]' : 'bg-red';
  return (
    <button
      type={type}
      className={classNames(
        isDisabled ? "cursor-default" : "cursor-pointer",
        btnClasses,
        "flex flex-row items-center gap-2 rounded-[2px_18px_0px_18px] p-3 py-2 text-center text-sm 2xl:text-md font-bold text-white"
      )}
      onClick={isDisabled ? () => null : onClick}
    >
      {children} {/* Render any children passed to the button */}
    </button>
  );
};
