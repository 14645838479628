import BackButton from "components/shared/buttons/BackButton";
import { ReactNode } from "react";

interface FormContainerProps {
  children: ReactNode;
  title: string;
  setWindowMode: React.Dispatch<React.SetStateAction<'projects' | 'newProject' | 'editProject' | 'newOrganization' | 'editOrganization'>>;
}

const FormContainer = ({ children, title, setWindowMode }: FormContainerProps) => {
  const onBackClick = () => {
    setWindowMode('projects');
  };
  return (
    <div
      className="flex h-[40.5vh] 2xl:h-[39vh] overflow-hidden overflow-y-auto flex-col gap-1 2xl:gap-4 rounded-md border-[1px] border-white p-4 shadow-lg"
      style={{
        background:
          "linear-gradient(180deg, rgba(180, 180, 180, 0.35) 0%, rgba(180, 180, 180, 0.15) 100%)",
      }}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-base font-bold text-white">{title}</h1>
        <BackButton onClick={onBackClick} />
      </div>
      {children}
    </div>
  );
};

export default FormContainer;
