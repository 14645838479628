import BorderGradient from 'components/shared/BorderGradient/BorderGradient';
import React, { useState } from 'react';
import { projectsapi } from 'utilities/Axios';

interface OrganizationModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode: 'edit' | 'create';
  initialData?: { id: string; name: string; desc: string };
  setRefreshMode: (value: string) => void;
}

const OrganizationModal: React.FC<OrganizationModalProps> = ({
  isOpen,
  onClose,
  mode,
  initialData = { id: '', name: '', desc: '' },
  setRefreshMode,
}) => {
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (mode === 'create') {
        await projectsapi.post('/api/Organisation', formData);
      } else {
        await projectsapi.put(`/api/Organisation/${formData.id}`, formData);
      }
      setRefreshMode('organizations');
      onClose();
    } catch (error) {
      console.error('Error:', error);
    }
    setIsLoading(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <BorderGradient className="w-full max-w-2xl p-6">
            
      <div className="bg-dark rounded-xl border border-white/[0.08] w-full max-w-2xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl text-white font-medium">
            {mode === 'create' ? 'Create Organization' : 'Edit Organization'}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white"
          >
            ✕
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm text-gray-400 mb-1">Organization Name</label>
            <input
              type="text"
              value={formData?.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
              placeholder="Enter organization name"
              required
            />
          </div>
          
          <div>
            <label className="block text-sm text-gray-400 mb-1">Description</label>
            <input
              type="text"
              value={formData?.desc}
              onChange={(e) => setFormData({ ...formData, desc: e.target.value })}
              className="w-full bg-[#2A2A2A] border border-white/[0.08] rounded-lg px-4 py-2 text-white focus:outline-none focus:border-blue-500"
              placeholder="Enter description"
              required
            />
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-400 hover:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg disabled:opacity-50"
            >
              {isLoading ? 'Processing...' : mode === 'create' ? 'Create' : 'Update'}
            </button>
          </div>
        </form>
      </div>
      
      </BorderGradient>
    </div>
  );
};

export default OrganizationModal; 