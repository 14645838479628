import { Link } from "react-router-dom";
// import { ProjectContainerProps } from "./domain/types";
import classNames from "utilities/ClassNames";
import { AddNewItemBtn } from "components/shared/buttons/AddNewItemBtn";
import { Tooltip } from "react-tooltip";
import TextOverflow from "utilities/TextOverflow";
import { SmallestLoader, SmallLoader } from "components/shared/Loader";

const ProjectContainer: React.FC<any> = ({ data, title, handleAddNew, isDisabled, isLoading, setSelectedProject }) => {
  const onEditClick = (project: any) => {
    setSelectedProject({ ...project, coordinates: JSON.parse(project.coordinates) });
  };
  return (
    <div
      className="flex h-[40.5vh] 2xl:h-[39vh] overflow-hidden overflow-y-auto flex-col gap-1 2xl:gap-4 rounded-md border-[1px] border-white p-4 shadow-lg"
      style={{
        background:
          "linear-gradient(180deg, rgba(180, 180, 180, 0.35) 0%, rgba(180, 180, 180, 0.15) 100%)",
      }}
    >
      <div className="flex gap-2 items-center">
        <h1 className="text-base font-bold text-white">{title}</h1>
        {isLoading && <SmallestLoader />}
      </div>
      <div className="flex flex-col flex-grow gap-2">
        <div className="flex-grow">
          <div className="flex flex-wrap gap-2 gap-y-2 h-full">
            {data.map((project: any, index: any) => {
              return (
                <Link
                  key={index}
                  target="_blank"
                  onClick={(e) => {
                    const target = e.target as HTMLElement;
                    if (target.closest(".edit-button")) {
                      e.preventDefault();
                    }
                  }}
                  to={project.link ? project.link : ''}
                  className={classNames(
                    project.link && project.link.length > 0 ? "cursor-pointer" : "cursor-default",
                    `2xl:h-[107px] h-[85px] w-[152px] rounded-md ${project.bgColor} p-[10px] shadow-[0_4px_12px_rgba(0,0,0,0.2)]`
                  )}
                >
                  <div>
                    <div className="flex items-center justify-between">
                      <span
                        data-tooltip-id={`tooltip-${index}`}
                        data-tooltip-content={project.title}
                        className="truncated-title 2xl:mt-2 2xl:mb-1 text-sm 2xl:text-md font-bold text-white"
                      >
                        {TextOverflow(project.title, 14)}
                      </span>
                      <Tooltip id={`tooltip-${index}`} />
                      {project.isEditable ? (
                        <button
                          className="cursor-pointer edit-button"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onEditClick(project);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="18px"
                            viewBox="0 -960 960 960"
                            width="18px"
                            fill="#FFFFFF"
                          >
                            <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z" />
                          </svg>
                        </button>
                      ) : (
                        <img
                          src={project.icon}
                          alt="icon"
                          className="object-contain mt-1"
                          style={{ width: "15px", height: "15px" }}
                        />
                      )}
                    </div>
                    <h6 className="my-1 text-xs font-bold text-white opacity-50">
                      {project.type}
                    </h6>
                    <h6 className="my-1 flex flex-row text-sm font-light text-white">
                      {project.status}&nbsp;
                    </h6>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end mt-auto">
          <AddNewItemBtn
            onClick={handleAddNew}
            btnType="filled"
            type="submit"
            isDisabled={isDisabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="15px"
              viewBox="0 -960 960 960"
              width="15px"
              fill="#FFFFFF"
            >
              <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
            </svg>
            Add New
          </AddNewItemBtn>
        </div>
      </div>
    </div>
  );
};

export default ProjectContainer;
