import React, { createContext, useContext, useState, ReactNode } from "react";

type Organization = {
  id: string;
  name: string;
  desc: string;
};

type OrganizationContextType = {
  selectedOrganization: Organization;
  setSelectedOrganization: (org: Organization) => void;
};

const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined);

export const OrganizationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>({
    id: "",
    name: "",
    desc: "",
  });

  return (
    <OrganizationContext.Provider value={{ selectedOrganization, setSelectedOrganization }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = (): OrganizationContextType => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error("useOrganization must be used within an OrganizationProvider");
  }
  return context;
};
