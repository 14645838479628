import Loader from "components/shared/Loader";
import React, { useMemo, useState } from "react";
import Debounce from "utilities/Debounce";
import useApp from "hooks/useApp";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import OrganizationHeader from 'components/organization/OrganizationHeader';
import OrganizationTabs from 'components/organization/OrganizationTabs';
import OrganizationActionMenu from "components/organization/OrganizationActionMenu";
import OrganizationModal from "components/Modal/OrganizationModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { removeOrganization } from "api/organizations";
import ProjectReassignModal from "components/Modal/ProjectReassignModal";
import { demoOrganizationsMockData } from "@/lib/constants";
import UsersTable from "components/organization/UsersTable";



export default function OrganizationAndUsersContainer() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [organizations, setOrganizations] = useState(demoOrganizationsMockData);
  const [expandedOrg, setExpandedOrg] = useState<number | null>(null);
  const {  isScaled } = useApp();
  const [activeTab, setActiveTab] = useState('organizations');
  const [refreshMode, setRefreshMode] = useState('');
  const [editOrg, setEditOrg] = useState<any>(null);
  const [deleteOrg, setDeleteOrg] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const [orgFilters, setOrgFilters] = useState({
    organization: '',
    subscriptionType: '',
    billingPeriod: '',
    paymentStatus: ''
  });

  const [userFilters, setUserFilters] = useState({
    organization: '',
    status: '',
    paymentStatus: '',
    paymentMethod: '',
    subscriptionType: '',
    userType: ''
  });

  const filteredOrgData = useMemo(() => {
    let filtered = [...organizations];
    
    if (orgFilters.organization) {
      filtered = filtered.filter(org => org.name.includes(orgFilters.organization));
    }
    
    if (orgFilters.subscriptionType) {
      filtered = filtered.filter(org => 
        org.users.some(user => user.subscriptionType === orgFilters.subscriptionType)
      );
    }
    
    return filtered;
  }, [organizations, orgFilters]);

  const handleDelete = async () => {
    if (deleteOrg) {
      try {
        await removeOrganization(deleteOrg);
        setRefreshMode('organizations');
        // Refresh organizations list
        setOrganizations(organizations.filter(org => org.id !== deleteOrg.id));
      } catch (error) {
        console.error('Error deleting organization:', error);
      }
      setDeleteOrg(null);
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error loading data</p>
        </div>
      )}
      {!loading && !error && organizations && (
        <div className={classNames(
          isScaled ? "text-2xl" : "text-base",
          "px-6 pb-3 overflow-hidden overflow-y-auto h-full"
        )}>
          <div className="pt-6 mb-6">
            <OrganizationHeader />
          </div>
          
          <OrganizationTabs 
            activeTab={activeTab} 
            onTabChange={setActiveTab}
            setRefreshMode={setRefreshMode}
            setOrgFilters={setOrgFilters}
            setUserFilters={setUserFilters}
            orgFilters={orgFilters}
            userFilters={userFilters}
          >
            {activeTab === 'organizations' ? (
              <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
                <table className="w-full">
                  <thead>
                    <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
                      <th className="text-left py-3 px-4">Organization</th>
                      <th className="text-left py-3 px-4">No. of subscribers</th>
                      <th className="text-left py-3 px-4">Created By</th>
                      <th className="text-left py-3 px-4">Created Date</th>
                      <th className="text-left py-3 px-4">Product</th>
                      <th className="text-right py-3 px-4">No. of Projects</th>
                      <th className="text-right py-3 px-4">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOrgData.map((org) => (
                      <React.Fragment key={org.id}>
                        <tr 
                          className="border-b border-gray-800/50 hover:bg-[#3A3A3A] cursor-pointer"
                          onClick={() => setExpandedOrg(expandedOrg === org.id ? null : org.id)}
                        >
                          <td className="py-3 px-4">
                            <div className="flex items-center gap-2">
                              {expandedOrg === org.id ? (
                                <FiChevronDown className="text-gray-400" />
                              ) : (
                                <FiChevronRight className="text-gray-400" />
                              )}
                              <span className="text-white">{org.name}</span>
                            </div>
                          </td>
                          <td className="py-3 px-4 text-gray-400">{org.subscriberCount}</td>
                          <td className="py-3 px-4 text-gray-400">{org.createdBy}</td>
                          <td className="py-3 px-4 text-gray-400">{org.createdDate}</td>
                          <td className="py-3 px-4 text-gray-400">{org.product}</td>
                          <td className="py-3 px-4 text-right text-gray-400">{org.projectCount}</td>
                          <td className="py-3 px-4 text-right">
                            <OrganizationActionMenu
                              organization={org}
                              onEdit={(org) => setEditOrg(org)}
                              onDelete={(org) => setDeleteOrg(org)}
                            />
                          </td>
                        </tr>
                        
                        {/* Users expanded view */}
                        {expandedOrg === org.id && (
                          <tr>
                            <td colSpan={7} className="bg-[#1a1a1a] border-b border-gray-800/50">
                              <div className="p-4">
                                <table className="w-full border-collapse">
                                  <thead>
                                    <tr className="text-gray-400 text-sm">
                                      <th className="text-left p-2">User ID</th>
                                      <th className="text-left p-2">Name</th>
                                      <th className="text-left p-2">Subscription type</th>
                                      <th className="text-left p-2">Product</th>
                                      <th className="text-right p-2">No of projects</th>
                                      <th className="text-left p-2">Projects</th>
                                      <th className="text-right p-2">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {org.users.map((user: any) => (
                                      <tr key={user.id} className="text-sm border-b border-gray">
                                        <td className="p-2 text-gray-400">{user.id}</td>
                                        <td className="p-2 text-white">{user.name}</td>
                                        <td className="p-2 text-gray-400">{user.subscriptionType}</td>
                                        <td className="p-2 text-blue-400">{user.product}</td>
                                        <td className="p-2 text-center text-gray-400">{user.projectCount}</td>
                                        <td className="p-2">
                                          <div className="flex gap-2">
                                            {user.projects.map((project: any, index: number) => (
                                              <button
                                                key={index}
                                                onClick={() => setSelectedUser(user)}
                                                className="text-blue-400 underline hover:text-blue-300"
                                              >
                                                {project.name}
                                              </button>
                                            ))}
                                          </div>
                                        </td>
                                        <td className="p-2 text-right">
                                          <button className="text-gray-400 hover:text-white">
                                            Edit
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <UsersTable filters={userFilters} />
            )}
          </OrganizationTabs>
        </div>
      )}

      <OrganizationModal
        isOpen={!!editOrg}
        onClose={() => setEditOrg(null)}
        mode="edit"
        initialData={editOrg}
        setRefreshMode={setRefreshMode}
      />

      <ConfirmationModal
        isOpen={!!deleteOrg}
        message="Are you sure you want to delete this organization?"
        onConfirm={handleDelete}
        onCancel={() => setDeleteOrg(null)}
      />

      <ProjectReassignModal
        isOpen={!!selectedUser}
        onClose={() => setSelectedUser(null)}
        userData={demoOrganizationsMockData[0].users[0] as any}
        allUsers={demoOrganizationsMockData[0].users}
      />

    </>
  );
}
