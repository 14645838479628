// components/SelectInput.tsx
import React from "react";

interface SelectInputProps {
  id: string;
  name: string;
  label: string; // Add label prop for display text
  value: string;
  options: { value: string; label: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
  id,
  name,
  label, // Use the new label prop
  value,
  options,
  onChange,
}) => {
  return (
    <div className="mb-4 w-full">
      <label htmlFor={id} className="block text-md font-medium text-gray-600">
        {label} {/* Use label for display */}
      </label>
      <select
        id={id}
        name={name} // Keep name for formState mapping
        required
        value={value}
        onChange={onChange}
        className="w-full p-2 border-white border border-x-0 border-t-0 text-[#959ca6] bg-transparent appearance-none focus:outline-none focus:ring-0"
      >
        <option value="" className="bg-[#959ca6] text-white">
          Select {label} {/* Use label for default option text */}
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="bg-[#959ca6] hover:bg-[#6b727a] text-black"
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
