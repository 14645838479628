import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { CoordinatesProvider } from "hooks/CoordinatesContext";
import { OrganizationProvider } from "hooks/OrganizationContext";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const oidcConfig = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_COMMON_APP}`,
  post_logout_redirect_uri: `${process.env.REACT_APP_COMMON_APP}`,
  scope: 'openid',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <OrganizationProvider>
        <CoordinatesProvider>
          <AppProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AppProvider>
        </CoordinatesProvider>
      </OrganizationProvider>
    </AuthProvider>
  </React.StrictMode>
);
