import React, { useState, useEffect } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Link, useNavigate } from "react-router-dom";
import ProjectHeader from "./ProjectHeader";
import ProjectContainer from "./ProjectContainer";
import NewProjectForm from "./Form/ProjectForm";
import FormContainer from "./Form/FormContainer";
import { getOrganizations, getOrganization as getOrganizationApi } from "api/organizations";
import OrganizationForm from "./Form/OrganizationForm";
import { getSolutions } from "api/solutions";
import { useCoordinates } from "hooks/CoordinatesContext";
import { useOrganization } from "hooks/OrganizationContext";

type Project = {
  id: string;
  name: string;
  desc: string;
  location: string;
  coordinates: [number, number] | null; // Nullable array of two numbers
  title: string;
  type: string; // Literal type
  bgColor: string;
  icon: string;
  status: string;
  link: string;
  isEditable: boolean;
};

const operationalExcelenceData = [
  {
    title: "EnergyHub",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://energyhub.processhub.ai/",
    isEditable: false
  },
  {
    title: "Leap24",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://leap24.processhub.ai/",
    isEditable: false
  },
  {
    title: "Data Center",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://datacenter.processhub.ai/",
    isEditable: false
  },
  {
    title: "Water Tower",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://culturalhub.processhub.ai/",
    isEditable: false
  },
  {
    title: "Car Simulator",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://car.processhub.ai/",
    isEditable: false
  },
  // {
  //   title: "SAAS",
  //   type: "FREE TRIAL",
  //   bgColor: "bg-gradient-to-b from-green to-blue",
  //   icon: "/images/cpu.svg",
  //   status: "Public Project",
  //   link: "https://product.processhub.ai/",
  //   isEditable: false
  // },
];

const progressMonitoringData = [
  {
    title: "DEMO PROJECT",
    type: "FREE TRIAL",
    category: "Progress Monitoring",
    bgColor: "bg-[#8A8A8A]",
    icon: "/images/cpu.svg",
    status: "Private Project",
    link: '',
    isEditable: false
  },
  {
    title: "DEMO PROJECT",
    type: "FREE TRIAL",
    category: "Progress Monitoring",
    bgColor: "bg-[#8A8A8A]",
    icon: "/images/cpu.svg",
    status: "Private Project",
    link: '',
    isEditable: false
  },
  {
    title: "DEMO PROJECT",
    type: "FREE TRIAL",
    category: "Progress Monitoring",
    bgColor: "bg-[#8A8A8A]",
    icon: "/images/cpu.svg",
    status: "Private Project",
    link: '',
    isEditable: false
  },
];

const initProject = {
  id: "",
  name: "",
  desc: "",
  location: "",
  coordinates: null,
  title: "",
  type: "PROJECT",
  bgColor: "",
  icon: "",
  status: "",
  link: "",
  isEditable: false,
};

interface ProjectHomeProps {
  setRefreshMode: React.Dispatch<React.SetStateAction<string>>;
  refreshMode: string;
}

const ProjectHome: React.FC<ProjectHomeProps> = ({ setRefreshMode, refreshMode }) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { selectedOrganization, setSelectedOrganization } = useOrganization();
  const [selectedProject, setSelectedProject] = useState<Project>(initProject);
  const [organizations, setOrganizations] = useState([]);
  const [windowMode, setWindowMode] = useState<'projects' | 'newProject' | 'editProject' | 'newOrganization' | 'editOrganization'>('projects');
  const [allProjects, setAllProjects] = useState<any>([]);
  const { setCoordinates } = useCoordinates();

  const [orgFormState, setOrgFormState] = useState({ id: '', name: '', desc: '' });
  const [projectFormState, setProjectFormState] = useState({
    projectName: "",
    productLocation: "",
    audience: "Public",
    building: "",
    coordinates: [null, null] as [number | null, number | null],
  });

  const handleAddNewOrganization = () => {
    setOrgFormState({ id: '', name: '', desc: '' });
    setWindowMode('newOrganization');
  };

  const handleAddNewProject = () => {
    setCoordinates([0, 0]);
    setSelectedProject(initProject);
    setWindowMode('newProject');
  };

  const getAllProjects = async (id: string) => {
    const response = await getSolutions(id);
    if (response.status === 200) {
      const newProjects = response.data.list.map((item: any) => {
        return {
          ...item,
          title: item.name,
          type: "PROJECT",
          bgColor: "bg-gradient-to-b from-green to-blue",
          icon: "/images/cpu.svg",
          status: "Private Project",
          link: item.url,
          isEditable: true
        };
      });
      selectedOrganization.id === '0ed25137-caf2-42a0-bac0-4cd3e6b1bc8f' ?
        setAllProjects([...operationalExcelenceData, ...newProjects]) :
        setAllProjects(newProjects);
      setIsLoading(false);
    }
    else {
      setError(response.data);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAllProjects(selectedOrganization.id);
    setWindowMode('projects');
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizations.length > 0) {
      setSelectedOrganization(organizations[0]);
      setIsLoading(false);
    }
  }, [organizations]);

  const setOrganizationsList = async () => {
    const response = await getOrganizations();
    if (response.status === 200) {
      setOrganizations(response.data.list);
    }
    else {
      setError(response.data);
    }
  };

  const getOrganization = async () => {
    const response = await getOrganizationApi(selectedOrganization.id);
    if (response.status === 200) {
      setOrgFormState(response.data.data);
    }
    else {
      setError(response.data);
    }
  };

  const handleOrgEdit = () => {
    getOrganization();
    setWindowMode('editOrganization');
  };

  useEffect(() => {
    setOrganizationsList();
  }, []);

  useEffect(() => {
    if (selectedProject.id !== '') {
      setWindowMode('editProject');
    }
  }, [selectedProject]);

  useEffect(() => {
    if (refreshMode === 'organizations') {
      setOrganizationsList();
    }
    if (refreshMode === 'solutions') {
      getAllProjects(selectedOrganization.id);
      setSelectedProject(initProject);
      setWindowMode('projects');
    }
    setRefreshMode('');
  }, [refreshMode]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section
      style={{
        position: "relative", // Needed for positioning the overlay
        backgroundImage: `url(/images/about.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        filter: "opacity(100%)",
      }}
      className="relative"
    >
      <div className="absolute inset-0 bg-black opacity-80 z-0"></div>
      <div className="relative z-10 pt-4">
        <ProjectHeader
          organizations={organizations}
          handleAddNew={handleAddNewOrganization}
          handleOrgEdit={handleOrgEdit}
          isLoading={isLoading}
        />
        {windowMode === 'projects' &&
          <PanelGroup
            autoSaveId="categories-resize"
            direction="horizontal"
            className="container h-auto pt-2 pb-4 rounded-lg"
          >
            {/* First Panel */}
            <Panel defaultSize={33} minSize={12}>
              <ProjectContainer title='OPERATIONAL EXCELENCE' data={allProjects} handleAddNew={handleAddNewProject} isDisabled={false} isLoading={isLoading} setSelectedProject={setSelectedProject} />
            </Panel>

            {/* Resize Handle */}
            <PanelResizeHandle className="flex w-px  items-center justify-center bg-orange-500">
              <div className="z-10 flex h-6 w-2 items-center justify-center rounded-sm border bg-white">
                <svg height="10" width="30" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0" y1="0" x2="0" y2="200" style={{ strokeWidth: 14 }} />
                </svg>
              </div>
            </PanelResizeHandle>



            {/* Third Panel */}
            <Panel defaultSize={33} minSize={12}>
              <ProjectContainer title='PROGRESS MONITORING' data={progressMonitoringData} handleAddNew={handleAddNewProject} isDisabled={true} isLoading={isLoading} setSelectedProject={setSelectedProject} />
            </Panel>
          </PanelGroup>
        }
        {windowMode === 'newProject' &&
          <div className="container pt-2 pb-4">
            <FormContainer title="CREATE PROJECT" setWindowMode={setWindowMode}>
              <NewProjectForm setRefreshMode={setRefreshMode} setError={setError} mode="create" formState={projectFormState} setFormState={setProjectFormState} setIsLoading={setIsLoading} selectedOrganization={selectedOrganization} selectedProject={selectedProject} />
            </FormContainer>
          </div>
        }
        {windowMode === 'editProject' &&
          <div className="container pt-2 pb-4">
            <FormContainer title="EDIT PROJECT" setWindowMode={setWindowMode}>
              <NewProjectForm setRefreshMode={setRefreshMode} setError={setError} mode="edit" formState={projectFormState} setFormState={setProjectFormState} setIsLoading={setIsLoading} selectedOrganization={selectedOrganization} selectedProject={selectedProject} />
            </FormContainer>
          </div>
        }
        {windowMode === 'newOrganization' &&
          <div className="container pt-2 pb-4">
            <FormContainer title="CREATE ORGANIZATION" setWindowMode={setWindowMode}>
              <OrganizationForm setRefreshMode={setRefreshMode} setError={setError} mode="create" formState={orgFormState} setFormState={setOrgFormState} setIsLoading={setIsLoading} />
            </FormContainer>
          </div>
        }
        {windowMode === 'editOrganization' &&
          <div className="container pt-2 pb-4">
            <FormContainer title="EDIT ORGANIZATION" setWindowMode={setWindowMode}>
              <OrganizationForm setRefreshMode={setRefreshMode} setError={setError} mode="edit" formState={orgFormState} setFormState={setOrgFormState} setIsLoading={setIsLoading} />
            </FormContainer>
          </div>
        }
      </div>
    </section>
  );
};

export default ProjectHome;