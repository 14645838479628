import React, { useState } from 'react';
import { FiMoreVertical, FiEdit2, FiTrash2 } from 'react-icons/fi';

interface ActionMenuProps {
  organization: { id: number | string; name: string; desc: string; users: any[] };
  onEdit: (org: { id: number | string; name: string; desc: string; users: any[] }) => void;
  onDelete: (org: { id: number | string; name: string; desc: string; users: any[] }) => void;
}

const OrganizationActionMenu: React.FC<ActionMenuProps> = ({
  organization,
  onEdit,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 hover:bg-white/[0.08] rounded-lg"
      >
        <FiMoreVertical className="text-gray-400" />
      </button>

      {isOpen && (
        <>
          <div
            className="fixed inset-0 z-10"
            onClick={() => setIsOpen(false)}
          />
          <div className="absolute right-0 mt-2 w-48 bg-[#1A1A1A] rounded-lg border border-white/[0.08] shadow-lg z-20">
            <button
              onClick={() => {
                onEdit(organization);
                setIsOpen(false);
              }}
              className="flex items-center gap-2 w-full px-4 py-2 text-sm text-gray-400 hover:bg-white/[0.08] hover:text-white"
            >
              <FiEdit2 />
              Edit Organization
            </button>
            <button
              onClick={() => {
                onDelete(organization);
                setIsOpen(false);
              }}
              className="flex items-center gap-2 w-full px-4 py-2 text-sm text-red-400 hover:bg-white/[0.08] hover:text-red-300"
            >
              <FiTrash2 />
              Delete Organization
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationActionMenu; 