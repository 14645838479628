export const usersMockData = [
    {
      id: 'PH34',
      name: 'Joy',
      userType: 'General Admin',
      status: 'Inhouse',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: 'NA',
      dueDate: 'NA',
      paymentStatus: 'NA',
      paymentMethod: 'NA',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 3
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Process Optimization',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
        
      ]
    },
    {
      id: 'PH76',
      name: 'Daniel',
      userType: 'Developer',
      status: 'Inhouse',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: 'NA',
      dueDate: 'NA',
      paymentStatus: 'NA',
      paymentMethod: 'NA',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 6
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Process Optimization',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
        {
          name: 'Cost Analysis',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Performance Metrics',
          role: 'User',
          owner: 'Jane Smith',
          isOwner: false
        },
        {
          name: 'Workflow Automation',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        }
      ]
    },
    {
      id: 'PH45',
      name: 'John',
      userType: 'Designer',
      status: 'Inhouse',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: 'NA',
      paymentStatus: 'NA',
      paymentMethod: 'NA',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 2
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
      
      ]
    },
    {
      id: 'PH54',
      name: 'Ann',
      userType: 'Subscriber',
      status: 'Expired',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: '04/05/2024',
      paymentStatus: 'Pending',
      paymentMethod: 'Credit card',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Operational Excellence',
          projectCount: 3
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
        
        {
          name: 'Performance Metrics',
          role: 'User',
          owner: 'Jane Smith',
          isOwner: false
        }
      ]
    },
    {
      id: 'PH66',
      name: 'Dany',
      userType: 'Subscriber',
      status: 'Active',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: '02/06/2024',
      paymentStatus: 'Paid',
      paymentMethod: 'Credit card',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 3
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Process Optimization',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
        {
          name: 'Resource Planning',
          role: 'User',
          owner: 'John Doe',
          isOwner: false
        },
       
      ]
    },
    {
      id: 'PH43',
      name: 'Joy',
      userType: 'Trial',
      status: 'Active',
      startDate: '12/03/2025',
      endDate: '12/03/2025',
      invoiceDate: '12/03/2025',
      dueDate: '19/01/2025',
      paymentStatus: 'Paid',
      paymentMethod: 'Credit card',
      organizations: [
        {
          id: '1',
          name: 'Acme Corporation',
          role: 'Admin',
          joinedDate: '12/03/2024',
          product: 'Process Monitoring',
          projectCount: 1
        }
      ],
      projects: [
        {
          name: 'Quality Assurance',
          role: 'Admin',
          owner: 'Donna May',
          isOwner: true
        },
       
      ]
    }
  ];


  
export const demoOrganizationsMockData = [
    {
      id: 1,
      name: "Acme Corporation",
      desc: "This is a description for Acme Corporation",
      subscriberCount: 15,
      createdBy: "John Doe",
      createdDate: "12/03/2024",
      product: "Process Monitoring",
      projectCount: 7,
      users: [
        {
          id: 'PH321',
          name: 'Donna May',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          organization: 'Acme Corporation',
          phoneNumber: '+1234567890',
          email: 'donna@example.com',
          role: 'admin',
          projects: [
            {
              name: 'Project1',
              role: 'Admin',
              owner: 'Donna May',
              isOwner: true
            },
            {
              name: 'Project2',
              role: 'User',
              owner: 'John Doe',
              isOwner: false
            },
            {
              name: 'Project3',
              role: 'Admin',
              owner: 'Donna May',
              isOwner: true
            },
            {
              name: 'Project4',
              role: 'User',
              owner: 'Jane Smith',
              isOwner: false
            }
          ]
        },
        {
          id: 'PH323',
          name: 'Stephen Ogden',
          subscriptionType: 'Standard',
          product: 'Operational Excellence',
          projectCount: 3,
          projects: ['Project1', 'Project2'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567891',
          email: 'stephen@example.com',
          role: 'user'
        },
        {
          id: 'PH324',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
        {
          id: 'PH325',
          name: 'Jane Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'jane@example.com',
          role: 'user'
        },
        {
          id: 'PH326',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
        
        {
          id: 'PH321',
          name: 'Donna May',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567890',
          email: 'donna@example.com',
          role: 'admin'
        },
        {
          id: 'PH323',
          name: 'Stephen Ogden',
          subscriptionType: 'Standard',
          product: 'Operational Excellence',
          projectCount: 3,
          projects: ['Project1', 'Project2'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567891',
          email: 'stephen@example.com',
          role: 'user'
        },
        {
          id: 'PH324',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
        {
          id: 'PH325',
          name: 'Jane Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'jane@example.com',
          role: 'user'
        },
        {
          id: 'PH326',
          name: 'John Doe',
          subscriptionType: 'Basic',
          product: 'Operational excellence',
          projectCount: 4,
          projects: ['Project1', 'Project2', 'Project3', 'Project4'],
          organization: 'Acme Corporation',
          phoneNumber: '+1234567892',
          email: 'john@example.com',
          role: 'user'
        },
  
      ],
    },
    {
      id: 2,
      name: "TechStart Solutions",
      desc: "This is a description for TechStart Solutions",
      subscriberCount: 2,
      createdBy: "Jane Smith",
      createdDate: "15/03/2024",
      product: "Process Monitoring",
      projectCount: 4,
      users: [
        {
            id: 'PH321',
            name: 'Donna May',
            subscriptionType: 'Basic',
            product: 'Operational excellence',
            projectCount: 4,
            organization: 'Acme Corporation',
            phoneNumber: '+1234567890',
            email: 'donna@example.com',
            role: 'admin',
            projects: [
              {
                name: 'Project1',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project2',
                role: 'User',
                owner: 'John Doe',
                isOwner: false
              },
              {
                name: 'Project3',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project4',
                role: 'User',
                owner: 'Jane Smith',
                isOwner: false
              }
            ]
          },
          {
            id: 'PH323',
            name: 'Stephen Ogden',
            subscriptionType: 'Standard',
            product: 'Operational Excellence',
            projectCount: 3,
            projects: ['Project1', 'Project2'],
            organization: 'Acme Corporation',
            phoneNumber: '+1234567891',
            email: 'stephen@example.com',
            role: 'user'
          },
      ],
    },
    {
      id: 3,
      name: "Global Innovations Ltd",
      desc: "This is a description for Global Innovations Ltd",
      subscriberCount: 4,
      createdBy: "Mike Johnson",
      createdDate: "18/03/2024",
      product: "Process Monitoring",
      projectCount: 3,
      users: [
        {
            id: 'PH321',
            name: 'Donna May',
            subscriptionType: 'Basic',
            product: 'Operational excellence',
            projectCount: 4,
            organization: 'Acme Corporation',
            phoneNumber: '+1234567890',
            email: 'donna@example.com',
            role: 'admin',
            projects: [
              {
                name: 'Project1',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project2',
                role: 'User',
                owner: 'John Doe',
                isOwner: false
              },
              {
                name: 'Project3',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project4',
                role: 'User',
                owner: 'Jane Smith',
                isOwner: false
              }
            ]
          },
          {
            id: 'PH323',
            name: 'Stephen Ogden',
            subscriptionType: 'Standard',
            product: 'Operational Excellence',
            projectCount: 3,
            projects: ['Project1', 'Project2'],
            organization: 'Acme Corporation',
            phoneNumber: '+1234567891',
            email: 'stephen@example.com',
            role: 'user'
          },
          {
            id: 'PH321',
            name: 'Donna May',
            subscriptionType: 'Basic',
            product: 'Operational excellence',
            projectCount: 4,
            organization: 'Acme Corporation',
            phoneNumber: '+1234567890',
            email: 'donna@example.com',
            role: 'admin',
            projects: [
              {
                name: 'Project1',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project2',
                role: 'User',
                owner: 'John Doe',
                isOwner: false
              },
              {
                name: 'Project3',
                role: 'Admin',
                owner: 'Donna May',
                isOwner: true
              },
              {
                name: 'Project4',
                role: 'User',
                owner: 'Jane Smith',
                isOwner: false
              }
            ]
          },
          {
            id: 'PH323',
            name: 'Stephen Ogden',
            subscriptionType: 'Standard',
            product: 'Operational Excellence',
            projectCount: 3,
            projects: ['Project1', 'Project2'],
            organization: 'Acme Corporation',
            phoneNumber: '+1234567891',
            email: 'stephen@example.com',
            role: 'user'
          },
      ],
    },
  ];

export const activityLogsMockData = [
  {
    timestamp: '2024-03-20 14:30:45',
    user: 'John Doe',
    userType: 'Admin',
    activity: 'Login',
    description: 'User logged in successfully',
    status: 'Success'
  },
  {
    timestamp: '2024-03-20 14:25:30',
    user: 'Jane Smith',
    userType: 'User',
    activity: 'Create Project',
    description: 'Created new project "Analytics Dashboard"',
    status: 'Success'
  },
  {
    timestamp: '2024-03-20 14:20:15',
    user: 'Mike Johnson',
    userType: 'Manager',
    activity: 'Update User',
    description: 'Updated user permissions',
    status: 'Failed'
  },
  {
    timestamp: '2024-03-20 14:15:00',
    user: 'Sarah Wilson',
    userType: 'User',
    activity: 'Delete Project',
    description: 'Deleted project "Old Dashboard"',
    status: 'Success'
  },
  // Add more activity logs...
];

export const emailLogsMockData = [
  {
    date: '2024-03-20',
    emailType: 'Welcome Email',
    status: 'Sent',
    sendTo: 'john@example.com'
  },
  {
    date: '2024-03-20',
    emailType: 'Password Reset',
    status: 'Failed',
    sendTo: 'jane@example.com'
  },
  {
    date: '2024-03-20',
    emailType: 'Verification',
    status: 'Sent',
    sendTo: 'mike@example.com'
  },
  {
    date: '2024-03-20',
    emailType: 'Newsletter',
    status: 'Pending',
    sendTo: 'sarah@example.com'
  },
  // Add more email logs...
];

export const auditTrailsMockData = [
  {
    date: '2024-03-20',
    user: 'John Doe',
    userType: 'Admin',
    change: 'Role Update',
    description: 'Changed user role from User to Admin'
  },
  {
    date: '2024-03-20',
    user: 'Jane Smith',
    userType: 'Manager',
    change: 'Permission Change',
    description: 'Added project management permissions'
  },
  {
    date: '2024-03-20',
    user: 'Mike Johnson',
    userType: 'User',
    change: 'Settings Update',
    description: 'Updated organization settings'
  },
  {
    date: '2024-03-20',
    user: 'Sarah Wilson',
    userType: 'Guest',
    change: 'Data Delete',
    description: 'Removed old project data'
  },
  // Add more audit trails...
];