import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const data = {

    timestamp: "2023-05-01, 12:00 AM",
    user: "Sam Johre",
    email: "samjohre@gmail.com",
    role: "Billing admin",
    ipAddress: "192.158.1.38",
    deviceInfo: "Google chrome, Windows",
    location: "Lorem ipsum",
    activity: "Login",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    status: "Success"

};

export default function ActivityDetails() {
    const navigate = useNavigate();

    const DetailRow = ({ label, value }: { label: string; value: string }) => (
        <div className="flex border-b border-white/[0.08] py-4">
            <div className="w-1/4 text-gray-400">{label}</div>
            <div className="w-3/4 text-white">{value}</div>
        </div>
    );

    return (
        <div className="px-6 pb-3 overflow-hidden overflow-y-auto h-full">
            <div className="flex items-center gap-4 py-6">
                <button
                    onClick={() => navigate(-1)}
                    className="text-gray-400 hover:text-white"
                >
                    <FiArrowLeft size={24} />
                </button>
                <h1 className="text-xl font-semibold text-white">Activity details</h1>
                <div className="flex-1" />
                <button className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg">
                    Report
                </button>
            </div>

            <div className="bg-[#2A2A2A] rounded-lg p-6">
                <div className="grid grid-cols-2 gap-8">
                    <div className="space-y-4">
                        <DetailRow label="Timestamp" value={data.timestamp} />
                        <DetailRow label="User" value={data.user} />
                        <DetailRow label="Email" value={data.email} />
                        <DetailRow label="Role" value={data.role} />
                    </div>

                    <div className="space-y-4">
                        <DetailRow label="IP address" value={data.ipAddress} />
                        <DetailRow label="Device Information" value={data.deviceInfo} />
                        <DetailRow label="Location" value={data.location} />
                        <DetailRow label="Activity" value={data.activity} />
                    </div>
                </div>

                <div className="mt-8 space-y-4">
                    <DetailRow label="Description" value={data.description} />
                    <div className="flex border-b border-white/[0.08] py-4">
                        <div className="w-1/4 text-gray-400">Status</div>
                        <div className="w-3/4">
                            <span className={`px-2 py-1 rounded-full text-xs ${data.status === 'Success' ? 'bg-[#1C3829] text-[#4ADE80]' :
                                    data.status === 'Failed' ? 'bg-[#e11d486b] text-red-500' :
                                        'bg-yellow-900/30 text-yellow-500'
                                }`}>
                                {data.status}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8">
                <h2 className="text-lg font-semibold text-white mb-4">Previous reports</h2>
                <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
                                <th className="text-left p-4">Created on</th>
                                <th className="text-left p-4">Reason to report</th>
                                <th className="text-left p-4">Assigned to</th>
                                <th className="text-left p-4">Status</th>
                                <th className="text-right p-4"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-b border-white/[0.08]">
                                <td className="p-4 text-gray-400">2023-05-01, 12:00 AM</td>
                                <td className="p-4 text-gray-400">Lorem ipsum dolor sit amet</td>
                                <td className="p-4 text-white">John Doe</td>
                                <td className="p-4">
                                    <span className="px-2 py-1 rounded-full text-xs bg-[#1C3829] text-[#4ADE80]">
                                        Success
                                    </span>
                                </td>
                                <td className="p-4 text-right">
                                    <button className="px-4 py-2 bg-[#3A3A3A] hover:bg-[#4A4A4A] text-white rounded-lg">
                                        Mark as resolved
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
} 