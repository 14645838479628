import ActivityLogs from 'components/logs/ActivityLogs';
import AuditTrails from 'components/logs/AuditTrails';
import EmailLogs from 'components/logs/EmailLogs';
import LogsHeader from 'components/logs/LogsHeader';
import LogsTabs from 'components/logs/LogsTabs';
import { useState } from 'react';

export default function Logs() {
  const [activeTab, setActiveTab] = useState('activity');
  const [selectedTime, setSelectedTime] = useState('all');
  const [activityFilters, setActivityFilters] = useState({
    userName: '',
    userType: '',
    activity: '',
    status: '',
    startDate: null,
    endDate: null
  });

  const [emailFilters, setEmailFilters] = useState({
    emailType: '',
    status: '',
    sendTo: '',
    date: '',
    startDate: null,
    endDate: null
  });

  const [auditFilters, setAuditFilters] = useState({
    userName: '',
    userType: '',
    change: '',
    date: ''
  });

  return (
    <div className="px-6 pb-3 overflow-hidden overflow-y-auto h-full">
      <div className="pt-6 mb-6">
        <LogsHeader selectedTime={selectedTime} setSelectedTime={setSelectedTime} />
      </div>
      
      <LogsTabs 
        activeTab={activeTab} 
        onTabChange={setActiveTab}
        activityFilters={activityFilters}
        setActivityFilters={setActivityFilters}
        emailFilters={emailFilters}
        setEmailFilters={setEmailFilters}
        auditFilters={auditFilters}
        setAuditFilters={setAuditFilters}
      >
        {activeTab === 'activity' && (
          <ActivityLogs filters={activityFilters} />
        )}
        {activeTab === 'email' && (
          <EmailLogs filters={emailFilters} />
        )}
        {activeTab === 'audit' && (
          <AuditTrails filters={auditFilters} />
        )}
      </LogsTabs>
    </div>
  );
} 