// components/Modal.tsx
import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import React from "react";

interface ModalProps {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, message, onConfirm, onCancel }) => {
  if (!isOpen) return null; // Don't render modal if it's not open

  return (
    <div className="fixed z-50 inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <BorderGradient>
        <div className="bg-dark p-6 rounded-lg shadow-lg w-max h-auto max-h-full overflow-auto">
          <h2 className="text-lg mb-4">{message}</h2>
          <div className="flex justify-between">
            <button
              onClick={onConfirm}
              type="button"
              className="bg-red text-white px-4 py-2 rounded-lg"
            >
              Confirm
            </button>
            <button
              onClick={onCancel}
              className="bg-green text-gray-800 px-4 py-2 rounded-lg"
            >
              Cancel
            </button>
          </div>
        </div>
      </BorderGradient>
    </div>
  );
};

export default Modal;
