import React, { useState, useMemo, useEffect } from 'react';
import ProjectReassignModal from '../Modal/ProjectReassignModal';
import { FiChevronDown, FiChevronRight, FiEdit2 } from 'react-icons/fi';
import { usersMockData } from '@/lib/constants';
import { getAllUsersWithOrganization } from 'api/users';

interface UserData {
  id: string;
  name: string;
  email: string;
  status: string;
  startDate: string;
  endDate: string;
  invoiceDate: string;
  dueDate: string;
  organization: string;
  paymentStatus: string;
  paymentMethod: string;
  projects: Array<{
    name: string;
    role: string;
    owner: string;
    isOwner: boolean;
  }>;
  organizations: Array<{
    id: string;
    name: string;
    role: string;
    joinedDate: string;
    product: string;
    projectCount: number;
  }>;
  userType: string;
}

interface UsersTableProps {
  filters: {
    organization: string;
    status: string;
    paymentStatus: string;
    paymentMethod: string;
    subscriptionType: string;
    userType: string;
  }
}

export default function UsersTable({ filters }: UsersTableProps) {
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [expandedUser, setExpandedUser] = useState<string | null>(null);
  const [openRoleDropdown, setOpenRoleDropdown] = useState<string | null>(null);
  const [users, setUsers] = useState(usersMockData);
  const [dropdownPosition, setDropdownPosition] = useState<'bottom' | 'top'>('bottom');

  const roles = [
    { id: 'general_admin', label: 'General Admin' },
    { id: 'developer', label: 'Developer' },
    { id: 'designer', label: 'Designer' },
    { id: 'subscriber', label: 'Subscriber' },
    { id: 'trial', label: 'Trial' }
  ];

  const getUsersWithOrganization = async () => {
    try { 
      const response: any = await getAllUsersWithOrganization();
      if (response.status === 200) {
        // console.log(response.data);
        if (response.data.list.length > 0) {
          setUsers(response.data.list);
        }
      }
    } catch (error) {
      console.error('Error fetching users with organization:', error);
    }
  };

  useEffect(() => {
    getUsersWithOrganization();
  }, []);

  const handleRoleChange = (userId: string, newRole: string) => {
    setUsers(prevUsers => 
      prevUsers.map(user => 
        user.id === userId 
          ? { ...user, userType: newRole }
          : user
      )
    );
    setOpenRoleDropdown(null);
  };

  const handleRoleClick = (userId: string, event: React.MouseEvent) => {
    const buttonElement = event.currentTarget;
    const rect = buttonElement.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    const dropdownHeight = 200; // Approximate height of dropdown

    // If space below is less than dropdown height and there's more space above
    if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
      setDropdownPosition('top');
    } else {
      setDropdownPosition('bottom');
    }

    setOpenRoleDropdown(openRoleDropdown === userId ? null : userId);
  };

  const PaymentStatusBadge = ({ status }: { status: string }) => {
    const getBadgeStyles = () => {
      switch (status?.toLowerCase()) {
        case 'pending':
          return 'bg-[#493323] text-[#FFA37A]';
        case 'paid':
          return 'bg-[#1C3829] text-[#4ADE80]';
        case 'na':
          return 'bg-gray-500/10 text-gray-400';
        default:
          return 'bg-gray-500/10 text-gray-400';
      }
    };

    return (
      <span className={`px-3 py-1 rounded-full text-xs ${getBadgeStyles()}`}>
        {status}
      </span>
    );
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user => {
      if (filters.userType && user.userType !== filters.userType) {
        return false;
      }
      if (filters.subscriptionType && user.status !== filters.subscriptionType) {
        return false;
      }
      if (filters.paymentStatus && user.paymentStatus !== filters.paymentStatus) {
        return false;
      }
      return true;
    });
  }, [filters, users]);

  return (
    <>
      <div className="text-white mb-4">
        Total Users: {filteredUsers.length}
      </div>
      <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
              <th className="w-10"></th>
              <th className="text-left p-4">User ID</th>
              <th className="text-left p-4">User Name</th>
              <th className="text-left p-4">Email</th>
              <th className="text-left p-4">Role</th>
              <th className="text-left p-4">Organization</th>
              <th className="text-left p-4">Status</th>
              <th className="text-left p-4">Start Date</th>
              {/* <th className="text-right p-4">End Date</th>
              <th className="text-left p-4">Invoice Date</th>
              <th className="text-left p-4">Due Date</th> */}
              <th className="text-left p-4">Payment Status</th>
              <th className="text-left p-4">Payment Method</th>
              <th className="text-right p-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user:any) => (
              <React.Fragment key={user.id}>
                <tr
                  className="border-b border-white/[0.08] hover:bg-[#3A3A3A]"
                >
                  <td className="pl-4">
                    <button
                      onClick={() => setExpandedUser(expandedUser === user.id ? null : user.id)}
                      className="p-2"
                    >
                      {expandedUser === user.id ? (
                        <FiChevronDown className="text-gray-400" />
                      ) : (
                        <FiChevronRight className="text-gray-400" />
                      )}
                    </button>
                  </td>
                  <td className="p-4 text-gray-400">{
                  // user.id 
                  ''}</td>
                  <td className="p-4">
                    <span className="text-white">{user.name || ''}</span>
                  </td>
                  <td className="p-4 text-gray-400">{user.email}</td>
                  <td className="p-4 relative">
                    <button
                      onClick={(e) => handleRoleClick(user.id, e)}
                      className="flex items-center gap-2 text-gray-400 hover:text-white px-2 py-1 rounded transition-colors"
                    >
                      {user.userType}
                      <FiChevronDown className="text-gray-500" />
                    </button>

                    {openRoleDropdown === user.id && (
                      <>
                        <div
                          className="fixed inset-0 z-10"
                          onClick={() => setOpenRoleDropdown(null)}
                        />
                        <div
                          className={`absolute ${
                            dropdownPosition === 'bottom' 
                              ? 'top-[100%]' 
                              : 'bottom-[100%]'
                          } left-0 w-48 bg-[#2A2A2A] rounded-md shadow-lg z-20 py-1 border border-white/[0.08]`}
                          style={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            maxHeight: '200px',
                            overflowY: 'auto'
                          }}
                        >
                          {roles.map((role) => (
                            <button
                              key={role.id}
                              onClick={() => handleRoleChange(user.id, role.label)}
                              className="w-full text-left px-4 py-2 text-gray-400 hover:bg-[#3A3A3A] hover:text-white transition-colors"
                            >
                              {role.label}
                            </button>
                          ))}
                        </div>
                      </>
                    )}
                  </td>


                  <td className="p-4 text-gray-400">{user.orgName}</td>
                  <td className="p-4 text-gray-400">{user.status}</td>
                  <td className="p-4 text-gray-400">{user.startDate}</td>
                  <td className="p-4">
                    <PaymentStatusBadge status={user.paymentStatus} />
                  </td>
                  <td className="p-4 text-gray-400">{user.paymentMethod}</td>
                  <td className="p-4 text-right">
                    <button className="text-gray-400 hover:text-white p-2 rounded transition-colors">
                      <FiEdit2 size={16} />
                    </button>
                  </td>
                </tr>

                {/* Organizations expanded view */}
                {expandedUser === user.id && (
                  <tr>
                    <td colSpan={11} className="bg-[#1a1a1a]">
                      <div className="p-4">
                        <table className="w-full">
                          <thead>
                            <tr className="text-gray-400 text-sm">
                              <th className="text-left p-2">Organization</th>
                              <th className="text-left p-2">Role</th>
                              <th className="text-left p-2">Joined Date</th>
                              <th className="text-left p-2">Product</th>
                              <th className="text-right p-2">No. of Projects</th>
                              <th className="text-right p-2">Projects</th>
                              <th className="text-right p-2">Actions</th>

                            </tr>
                          </thead>
                          <tbody>
                            {user?.organizations?.map((org:any) => (
                              <tr key={org.id} className="border-b border-gray">
                                <td className="p-2 text-white">{org.name}</td>
                                <td className="p-2 text-gray-400">{org.role}</td>
                                <td className="p-2 text-gray-400">{org.joinedDate}</td>
                                <td className="p-2 text-gray-400">{org.product}</td>
                                <td className="p-2 text-right text-gray-400">{org.projectCount}</td>
                                <td className="p-4">
                                  <div className="flex items-center gap-2">
                                    {user?.projects?.slice(0, 2).map((project:any, index:any) => (
                                      <button
                                        key={index}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedUser(user);
                                        }}
                                        className="text-blue-400 underline hover:text-blue-300"
                                      >
                                        {project.name}
                                      </button>
                                    ))}
                                    {user.projects.length > 2 && (
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedUser(user);
                                        }}
                                        className="px-2 py-1 text-xs rounded-full bg-blue-500/10 text-blue-400 hover:bg-blue-500/20"
                                      >
                                        {user.projects.length - 2} more projects
                                      </button>
                                    )}
                                  </div>
                                </td>
                                <td className="p-2 text-right">
                                  <button className="text-gray-400 hover:text-white">
                                    Edit
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      {selectedUser && (
        <ProjectReassignModal
          isOpen={!!selectedUser}
          onClose={() => setSelectedUser(null)}
          userData={selectedUser}
          allUsers={usersMockData}
        />
      )}
    </>
  );
} 