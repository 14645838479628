import { CgSpinnerTwo } from "react-icons/cg";

export default function Loader() {
  return <CgSpinnerTwo size={50} className="animate-spin text-green" />;
}

export function SmallLoader() {
  return <CgSpinnerTwo size={35} className="animate-spin text-white" />;
}

export function SmallestLoader() {
  return <CgSpinnerTwo size={20} className="animate-spin text-white" />;
}
