import { projectsapi } from "utilities/Axios";

export const getOrganizations = () => {
  return projectsapi.get("/api/Organisation/my-organisations");
};

export const getOrganization = (id: string) => {
  return projectsapi.get(`/api/Organisation/${id}`);
};

export const postOgranization = (data: { name: string }) => {
  return projectsapi.post("/api/Organisation", data);
};

export const editOrganization = (data: any) => {
  return projectsapi.put(`/api/Organisation/${data.id}`, data);
};

export const removeOrganization = (data: any) => {
  return projectsapi.delete(`/api/Organisation/${data.id}`);
};