import { projectsapi } from "utilities/Axios";

export const getSolutions = (id: string) => {
  return projectsapi.get(`/api/o/${id}/Solution/my-solution`);
};

export const postSolution = (id: string, data: any) => {
  return projectsapi.post(`/api/o/${id}/Solution/add-solution`, data);
};

export const editSolution = (id: string, solutionId: string, data: any) => {
  return projectsapi.put(`/api/o/${id}/Solution/${solutionId}`, data);
};

export const removeSolution = (id: string, solutionId: string) => {
  return projectsapi.delete(`/api/o/${id}/Solution/${solutionId}`);
};