import classNames from "utilities/ClassNames";

export const AddNewItemBtn = ({
  onClick,
  btnType,
  type,
  isDisabled,
  children,
}: {
  onClick?: () => void;
  btnType: 'filled' | 'hollow';
  type: 'submit' | 'button';
  isDisabled: boolean;
  children: React.ReactNode; // This can be any valid React child (text, JSX, components)
}) => {
  const btnClasses = {
    filled: isDisabled ? 'bg-[#8A8A8A]' : 'bg-gradient-to-b from-green to-blue',
    hollow: isDisabled ? 'border-2 border-[#8A8A8A]' : 'border-2 border-blue',
  };
  return (
    <button
      type={type}
      className={classNames(
        btnClasses[btnType],
        isDisabled ? "cursor-default" : "cursor-pointer",
        "flex flex-row items-center gap-2 rounded-[2px_18px_0px_18px] p-3 py-2 text-center text-sm 2xl:text-md font-bold text-white"
      )}
      onClick={isDisabled ? () => null : onClick}
    >
      {children} {/* Render any children passed to the button */}
    </button>
  );
};
