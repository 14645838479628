import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function ReportedActivity() {
  const navigate = useNavigate();

  return (
    <div className="px-6 pb-3 overflow-hidden overflow-y-auto h-full">
      <div className="flex items-center gap-4 py-6">
        <button 
          onClick={() => navigate(-1)}
          className="text-gray-400 hover:text-white"
        >
          <FiArrowLeft size={24} />
        </button>
        <h1 className="text-xl font-semibold text-white">Reported Activity logs</h1>
      </div>

      <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-[#1A1A1A] text-gray-400 text-sm">
              <th className="text-left p-4">Created on</th>
              <th className="text-left p-4">Reason to report</th>
              <th className="text-left p-4">Assigned to</th>
              <th className="text-left p-4">Status</th>
              <th className="text-right p-4"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-white/[0.08] hover:bg-[#3A3A3A]">
              <td className="p-4 text-gray-400">2023-05-01, 12:00 AM</td>
              <td className="p-4 text-gray-400">Lorem ipsum dolor sit amet</td>
              <td className="p-4 text-white">John Doe</td>
              <td className="p-4">
                <span className="px-2 py-1 rounded-full text-xs bg-[#1C3829] text-[#4ADE80]">
                  Success
                </span>
              </td>
              <td className="p-4 text-right">
                <button className="px-4 py-2 bg-[#3A3A3A] hover:bg-[#4A4A4A] text-white rounded-lg">
                  Mark as resolved
                </button>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
} 