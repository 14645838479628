import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { ProjectLayout } from "components/Project/ProjectLayout";
import ProjectHome from "components/Project/ProjectHome";
import { useCoordinates } from "hooks/CoordinatesContext";
import { getSolutions } from "api/solutions";
import { useOrganization } from "hooks/OrganizationContext";

export default function Dashboard() {
  const { coordinates, setCoordinates } = useCoordinates();
  const [refreshMode, setRefreshMode] = useState('');
  const [loading, setLoading] = useState(true);
  const [menuShown, setMenuShown] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [userProjects, setUserProjects] = useState<any>([]);
  const { selectedOrganization } = useOrganization();
  const [center, setCenter] = useState({
    lat: 24,
    lng: 42.388945,
  });

  const getAllProjects = async (id: string) => {
    const response = await getSolutions(id);
    if (response.status === 200) {
      const newProjects = response.data.list.map((item: any) => {
        return {
          ...item,
          title: item.name,
          type: "PROJECT",
          bgColor: "bg-gradient-to-b from-green to-blue",
          icon: "/images/cpu.svg",
          status: "Private Project",
          link: item.url,
          isEditable: true
        };
      });
      setUserProjects(newProjects);
    }
  };

  useEffect(() => {
    if (coordinates[0] !== null && coordinates[0] !== 0 && coordinates[1] !== null && coordinates[1] !== 0) {
      setCenter({ lat: coordinates[0], lng: coordinates[1] });
    }
  }, [coordinates]);

  useEffect(() => {
    setUserProjects([]);
    getAllProjects(selectedOrganization.id);
  }, [selectedOrganization, refreshMode]);

  const containerStyle = {
    width: "100vw",
    height: "47vh",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCaAnlPllExl2Yuk5G6RENFb0zlBQUXFWY",
  });
  useEffect(() => {
    if (isLoaded) {
      setLoading(false);
    }
  }, [isLoaded, userProjects]);
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const styles = require("./googlemapstyles.json");
  const [map, setMap] = React.useState(null);
  const onLoad = () => {
    setMenuShown(true);
  };
  const onMapClick = (event: any) => {
    // Capture the latitude and longitude from the event
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });
    setCoordinates([Number(lat), Number(lng)]);
  };

  return !loading ? (
    <ProjectLayout>
      {menuShown && <ProjectHome setRefreshMode={setRefreshMode} refreshMode={refreshMode} />}
      <GoogleMap
        mapContainerStyle={{ ...containerStyle, position: 'relative', zIndex: 1 }}
        center={center}
        zoom={5.5}
        onLoad={onLoad}
        onClick={onMapClick}
        options={{
          styles: styles,
          zoomControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          minZoom: 3,
          maxZoom: 7,
        }}
      >
        {selectedOrganization.id === '0ed25137-caf2-42a0-bac0-4cd3e6b1bc8f' &&
          <>
            <Marker
              label={{
                text: "Water Tower",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 26.4, lng: 50 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_CULTURAL_APP}`, "_self");
              }}
            />
            <Marker
              label={{
                text: "Energy",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 27.7, lng: 48.8 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_ENERGY_APP}`, "_self");
              }}
            />

            <Marker
              label={{
                text: "Leap24",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 25.2, lng: 46.4 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_LEAP24_APP}`, "_self");
              }}
            />

            <Marker
              label={{
                text: "Data Center",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 24.7, lng: 46.85 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_DATACENTER_APP}`, "_self");
              }}
            />

            <Marker
              label={{
                text: "Car Simulator",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 21, lng: 39.3 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_REALESTATE_APP}`, "_self");
              }}
            />
          </>
        }
        {coordinates[0] !== null && coordinates[1] !== null && <Marker
          label={{
            text: " ",
            color: "#000",
            className: "",
          }}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10, // Size of the marker
            fillColor: "#14b8a6", // Custom color
            fillOpacity: 1, // Make it solid
            strokeWeight: 2, // Outline width
            strokeColor: "#000000", // Outline color
          }}
          position={{ lat: coordinates[0], lng: coordinates[1] }}
          onClick={() => null}
        />}
        {userProjects &&
          userProjects.map((project: any) => {
            const coords = JSON.parse(project.coordinates);
            return coords ? (
              <Marker
                key={project.id}
                label={{
                  text: project.name,
                  color: "#12E4DE",
                  className: "watertowertext",
                }}
                position={{ lat: coords[0], lng: coords[1] }}
                onClick={() => window.open(project.url, "_blank")}
              />
            ) : null;
          })}
      </GoogleMap>
    </ProjectLayout>
  ) : (
    <></>
  );
}