// components/FormInput.tsx
import React from "react";

interface FormInputProps {
  id: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput: React.FC<FormInputProps> = ({
  id,
  name,
  value,
  placeholder,
  onChange,
}) => {
  return (
    <div className="mb-4 w-full">
      <label
        htmlFor={id}
        className="block text-md font-medium text-gray-600"
      >
        {placeholder}
      </label>
      <input
        type="text"
        required
        id={id}
        name={name}
        placeholder={`EX: ${placeholder}`}
        value={value}
        onChange={onChange}
        className="w-full p-2 border bg-transparent border-x-0 border-t-0 focus:outline-none"
      />
    </div>
  );
};

export default FormInput;
